import { render, staticRenderFns } from "./cmsMagazines.vue?vue&type=template&id=51687cbc"
import script from "./cmsMagazines.vue?vue&type=script&lang=js"
export * from "./cmsMagazines.vue?vue&type=script&lang=js"
import style0 from "./cmsMagazines.vue?vue&type=style&index=0&id=51687cbc&prod&lang=css"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports